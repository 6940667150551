import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../core/services/auth.service';
import { parseJwtClaim } from 'src/auth/auth';
import { ConsultantService } from '../shared/services/consultant.service';

@Component({
  selector: 'app-oauth-redirect',
  templateUrl: './oauth-redirect.component.html',
})
export class OAuthRedirect implements OnInit {
  constructor(
    private authService: AuthService,
    private consultantService: ConsultantService
  ) {}

  ngOnInit(): void {
    const hashParams = window.location.hash.substring(1).split('&');
    const params = hashParams.reduce((result, param) => {
      const [key, value] = param.split('=');
      result[key] = value;
      return result;
    }, {} as { [key: string]: string });

    if (params['id_token']) {
      const nonce = sessionStorage.getItem('nonce');
      const payload = parseJwtClaim(params['id_token']);
      if (payload.nonce !== nonce || !payload.email) {
        this.authService.signOut();
        return;
      }
      sessionStorage.setItem('sso_token', params['id_token']);
      this.authService.setSession({
        signInUserSession: {
          idToken: { payload: { 'cognito:groups': ['consultants'] } },
        },
      });
      this.consultantService
        .createConsultant({
          EMAIL: payload.email,
          NAME: payload.name || '',
        })
        .subscribe({
          next: () => {
            this.authService.redirectUrl();
          },
          error: () => {
            this.authService.signOut();
          },
        });
    } else {
      this.authService.signOut();
    }
  }
}
